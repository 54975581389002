export const LOGIN_ROUTE = "/login";
export const HOME_ROUTE = "/";
export const QR_SCANNER_ROUTE = "/qr-scanner";
export const QR_SCANNER_SESSION_ROUTE = "/qr-scanner/:id";
export const QR_SCAN = "/qr-scan/:id";
export const BUYER_SESSIONS_ROUTE = "/buyer-sessions";
export const PROFILE_ROUTE = "/profile";
export const SINGLE_SCAN = "/qr-scanner/single";
export const BUYER_SESSION_ROUTE = "/buyer-session/:id";
export const PRODUCT = "/product/:hash";

