import produce from "immer";
import { patchBuyer, postMail, addBuyer } from "./api";
import { getGlobal, setGlobal } from "./commonFunctions";
import { supabase } from "./supabase";

export const getBuyerSession = () => {
  return getGlobal("buyerSession");
};

export const setBuyerSession = (buyer) => {
  return setGlobal("buyerSession", buyer);
};

export const deleteBuyerSession = () => {
  setBuyerSession(null);
};

export const sendMailCurrentBuyer = async () => {
  try {
    return await postMail(getBuyerSession());
  } catch (e) {
    console.log("Error sending mail to current buyer", e);
  }
};

export const isBuyerSession = () => {
  const session = getBuyerSession();
  return !!session && !!session.id;
};

// process
export const processProductVisited = async (productHash, buyerSession) => {
  const visitedProducts = buyerSession.visited_products || [];

  if (!visitedProducts.filter((item) => item.hash === productHash).length) {
    const currentBuyer = {
      ...buyerSession,
      is_synced: false,
      visited_products: [
        ...visitedProducts,
        {
          hash: productHash,
          isFav: false,
          comments: "",
        },
      ],
    };

    setBuyerSession(currentBuyer);
    await patchBuyer(currentBuyer?.id, {
      visited_products: currentBuyer.visited_products,
    });
  }
};

export const processDeleteBuyerSession = async () => {
  let mailStatus;
  if (isBuyerSession()) {
    try {
      mailStatus = (
        await Promise.all([
          // sendMailCurrentBuyer(),
          patchBuyer(getBuyerSession()?.id, {
            is_active: false,
          }),
        ])
      )[0];
    } catch (e) {
      console.log("Error sending mail while deleting buyer session", e);
    }
    deleteBuyerSession();
  }
  return {
    mailStatus,
  };
};

export const processNewBuyer = async (values) => {
  let responseMessage;
  // send Mail to previous buyer
  try {
    responseMessage = await processDeleteBuyerSession();
  } catch (e) {
    console.log("Error deleting buyer session while creating new buyer", e);
  }
  // call API
  const buyer = await addBuyer(values);
  // set buyer
  setBuyerSession(buyer);

  return buyer;
};
