import {
  Modal,
  Row,
  Typography,
  Card,
  Badge,
  Col,
  Divider,
  Button,
  Skeleton,
} from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import { RequestStatusModal } from "./RequestStatusModal";
import { UpgradeRequestModal } from "./UpgradeRequestModal";
import { LogoutComponent } from "./LogoutComponent";
import { PostRequestContent } from "./PostRequestContent";

const { Title, Text } = Typography;
export const UpgradePlanModal = ({
  data,
  isExpired,
  isUpgradeModal,
  setIsUpgradeModal,
}) => {
  const [selectedPlan, setSelectedPlan] = useState("");
  const [loading, setLoading] = useState(false);
  const [planData, setPlanData] = useState(null);
  const [planId, setPlanId] = useState();
  const [addOnData, setAddOnData] = useState(null);
  const [payloadData, setPayloadData] = useState(null);
  const [isPendingRequest, setIspendingRequest] = useState(false);
  const [upgradeRequestModal, setUpgradeRequestModal] = useState(false);

  useEffect(() => {
    if (isUpgradeModal) {
      getAvailableUpgradeData();
    }
  }, [isUpgradeModal]);
  const getAvailableUpgradeData = () => {
    const moduleId = data && data?.module_id;
    const user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user && user?.access_token;
    setLoading(true);
    setSelectedPlan("");
    axios
      .get(`${process.env.REACT_APP_API_SERVICE}upgrade/${moduleId}`, {
        headers: { "access-token": `${accessToken}` },
      })
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          const data = res?.data;
          if (data?.pending_requests?.length > 0) {
            const isPlanRequestPending = data?.pending_requests?.filter(
              (item) => item?.feature_type === "plan"
            );
            if (isPlanRequestPending?.length > 0) {
              // setIsUpgradeModal(false);
              setIspendingRequest(true);
              // setRequestStatusModal(true);
              return;
            }
            if (moduleId) {
              const isModuleRequest = data?.pending_requests?.filter(
                (item) => item?.module_id === moduleId
              );
              if (isModuleRequest?.length > 0) {
                // setIsUpgradeModal(false);
                // setRequestStatusModal(true);
                setIspendingRequest(true);
                return;
              }
            }
          }
          setAddOnData(res?.data?.available_addon);
          setPlanData(res?.data?.available_plans);
          setPlanId(res?.data?.available_plan?.id);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const handleSendRequest = () => {
    if (selectedPlan === "addon") {
      setPayloadData({
        request_type: "upgrade",
        feature_type: selectedPlan,
        feature_identifier: addOnData?.addon_details?.id,
        module_id: addOnData?.addon_details?.module_id,
      });
    }
    if (selectedPlan === "plan") {
      setPayloadData({
        request_type: "upgrade",
        feature_type: selectedPlan,
        feature_identifier: planData[0]?.id,
      });
    }
    if (!isExpired) {
      setIsUpgradeModal(false);
    }
    setUpgradeRequestModal(true);
  };

  return (
    <>
      <Modal
        width={338}
        closable={isExpired ? false : true}
        visible={isUpgradeModal}
        onCancel={() => {
          if (isExpired) {
            return;
          }
          setIsUpgradeModal(false);
        }}
        footer={null}
      >
        {loading ? (
          <Row>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Row>
        ) : isPendingRequest ? (
          <PostRequestContent />
        ) : (
          planData && (
            <div>
              <Row>
                <Title level={5}>
                  {isExpired
                    ? "Your trade fair assistant trial has expired"
                    : "Choose to upgrade"}
                </Title>
                <Text style={{ color: "#686E6D" }}>
                  Select add on or upgrade plan to get trade fair assistant
                  module
                </Text>
              </Row>
              <br />
              <Row
                style={{ overflowY: "auto", maxHeight: "30rem", width: "100%" }}
                align="center"
              >
                {planData && planData?.length > 0 && (
                  <Col>
                    <Badge
                      offset={[-210, 12]}
                      count={"Recommended"}
                      color="linear-gradient(92.76deg, #694CBB -4.7%, #FFA42B 150.67%)"
                    >
                      <Card
                        style={{
                          marginTop: "0.8rem",
                          cursor: "pointer",
                          border: `${
                            selectedPlan === "plan"
                              ? "1px solid #0B7764"
                              : "1px solid #CDCFCE"
                          }`,
                          background: `${
                            selectedPlan === "plan" ? "#F0F8F6" : "#FFFFFF"
                          }`,
                          borderRadius: "0.4rem",
                          minWidth: "17.5rem",
                        }}
                        onClick={() => setSelectedPlan("plan")}
                      >
                        <Row>
                          <Col>
                            <Title level={5}>
                              Upgrade to {planData[0]?.label}
                            </Title>
                          </Col>
                        </Row>
                        {planData[0]?.meta?.benefits?.map((el) => {
                          return (
                            <Row style={{ marginTop: "0.5rem" }}>
                              <Col>
                                <CheckCircleFilled
                                  style={{
                                    color: "#E6A145",
                                    marginRight: "0.5rem",
                                  }}
                                />
                              </Col>
                              <Col style={{ width: "80%", color: "#686E6D" }}>
                                {el}
                              </Col>
                            </Row>
                          );
                        })}
                        {selectedPlan === "plan" && (
                          <Row>
                            <Text
                              style={{
                                color: "#0B7764",
                                marginTop: "1rem",
                                fontWeight: "bold",
                              }}
                            >
                              Selected
                            </Text>
                          </Row>
                        )}
                      </Card>
                    </Badge>
                  </Col>
                )}
                {addOnData && Object.keys(addOnData).length > 0 && (
                  <Col>
                    <Card
                      style={{
                        marginTop: "0.8rem",
                        cursor: "pointer",
                        border: `${
                          selectedPlan === "addon"
                            ? "1px solid #0B7764"
                            : "1px solid #CDCFCE"
                        }`,
                        background: `${
                          selectedPlan === "addon" ? "#F0F8F6" : "#FFFFFF"
                        }`,
                        borderRadius: "0.4rem",
                        minWidth: "17.5rem",
                      }}
                      onClick={() => setSelectedPlan("addon")}
                    >
                      <Row>
                        <Col style={{ width: "90%" }}>
                          <Title level={5}>
                            {addOnData?.addon_details?.label}
                          </Title>
                        </Col>
                      </Row>
                      <Row>
                        <Text style={{ fontWeight: "bold" }}>
                          +₹ {addOnData?.addon_details?.amount} /year
                        </Text>
                      </Row>
                      {addOnData?.addon_details?.meta?.line_items?.map((el) => {
                        return (
                          <Row style={{ marginTop: "0.5rem" }}>
                            <Col>
                              <CheckCircleFilled
                                style={{
                                  color: "#E6A145",
                                  marginRight: "0.5rem",
                                }}
                              />
                            </Col>
                            <Col style={{ width: "80%" }}>{el}</Col>
                          </Row>
                        );
                      })}
                      {selectedPlan === "addon" && (
                        <Row>
                          <Text
                            style={{
                              color: "#0B7764",
                              marginTop: "1rem",
                              fontWeight: "bold",
                            }}
                          >
                            Selected
                          </Text>
                        </Row>
                      )}
                    </Card>
                  </Col>
                )}
              </Row>
              <Divider />
              <Row>
                <Button
                  disabled={selectedPlan === ""}
                  size="large"
                  style={{
                    width: "100%",
                    border: "none",
                    background: `${selectedPlan === "" ? "#E6E7E7" : "black"}`,
                    color: "white",
                    borderRadius: "0.3rem",
                  }}
                  onClick={handleSendRequest}
                >
                  Send request to buy
                </Button>
              </Row>
            </div>
          )
        )}
        {isExpired && (
          <LogoutComponent
            isButton={false}
            isStartTrialModal={isUpgradeModal}
            setIsTrialModal={setIsUpgradeModal}
          />
        )}
      </Modal>
      {/* <RequestStatusModal
        justSent={false}
        requestStatusModal={requestStatusModal}
        setRequestStatusModal={setRequestStatusModal}
      /> */}
      <UpgradeRequestModal
        data={payloadData}
        isExpired={isExpired}
        upgradeRequestModal={upgradeRequestModal}
        setUpgradeRequestModal={setUpgradeRequestModal}
      />
    </>
  );
};
