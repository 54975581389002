import { postLogin } from "./api";
import { processDeleteBuyerSession } from "./buyerSession";
import { getGlobal, setGlobal } from "./commonFunctions";

export const getUserSession = () => {
  return getGlobal("user");
};

export const setUserSession = (user) => {
  setGlobal("user", user);
};

export const deleteUserSession = () => {
  setUserSession(null);
};

export const login = async (payload) => {
  const data = await postLogin(payload);
  if (!data || !data.access_token) {
    throw new Error("No access token found");
  }
  setUserSession(data);
};

export const logout = async () => {
  try {
    await processDeleteBuyerSession();
  } catch (e) {
    console.log("Error deleting buyer session while logging out", e);
  }
  deleteUserSession();
};

export const isLoggedIn = () => {
  const user = getUserSession();
  return !!user && !!user.access_token;
};
