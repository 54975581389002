import { Modal, Typography, Row, Col, Button } from "antd";
import style from "styled-components";
import { LogoutComponent } from "./LogoutComponent";
const { Title, Text } = Typography;
const SubDiv = style.div`
margin-bottom: 1rem;
padding: 1.2rem;
width: 100%;
border-radius: 0.5rem;
background: linear-gradient(271.08deg, rgb(255, 217, 166, 0.33), rgb(187, 211, 247, 0.33) 104.21%);
mix-blend-mode: normal;
`;
export const TrialExpiredModal = ({ isModalVisible, setIsModalVisible }) => {
  return (
    <>
      <Modal
        footer={null}
        visible={isModalVisible}
        closable={false}
        width={348}
      >
        <SubDiv>
          <Row align="center">
            <Title level={5} style={{ fontWeight: "900" }}>
              Your free trial has expired
            </Title>
          </Row>
          <Row align="center">
            {/* <Col style={{width:'80%'}}> */}
            <Text style={{ color: "#4F5655" }}>
              Select from our range of plans that best
            </Text>
            <Text style={{ color: "#4F5655" }}>suit for your requirements</Text>
            {/* </Col> */}
          </Row>
        </SubDiv>
        <LogoutComponent
          isButton={true}
          isStartTrialModal={isModalVisible}
          setIsTrialModal={setIsModalVisible}
        />
      </Modal>
    </>
  );
};
