import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTE } from "../../constants";
import { logout } from "../../utils/auth";
import LogoutIcon from "../../assets/images/vector-out.svg";
import FullScreenLoader from "../loaders/FullScreenLoader";
import { useSWRConfig } from "swr";

const LogoutButton = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const { cache } = useSWRConfig();

  const onClick = useCallback(() => {
    setLoading(true);
    cache.clear();
    logout().then(() => {
      setLoading(false);
      navigate(HOME_ROUTE);
    });
  }, [navigate, cache]);

  return (
    <div className="logout-button" onClick={onClick}>
      <img src={LogoutIcon} alt="logout" />
      <div>{isLoading ? "Saving data..." : "Log out"}</div>
      {isLoading && <FullScreenLoader />}
    </div>
  );
};

export default LogoutButton;
