import React, { useContext } from "react";
import { produce } from "immer";

// Carrier Produce : https://immerjs.github.io/immer/curried-produce
// in `produce(reducer)` immer will convert the function where
// first argument will be replaced with mutable
const useImmerProducer = (reducer, initState) => {
  return React.useReducer(produce(reducer), initState);
};

// actions
export const SET_PRODUCT_ID = "SET_PRODUCT_ID";
export const NEW_PRODUCT_HASH = "NEW_PRODUCT_HASH";
export const PRODUCT_CONTEXT =  "PRODUCT_CONTEXT";

// state
const initialState = {};

// reducer
const actionReducer = (state, action) => {
  switch (action.type) {
    case SET_PRODUCT_ID:
      state.productId = action.productId;
      return;
      case NEW_PRODUCT_HASH:
        state.newProductHash = action.productId;
        return;
        case PRODUCT_CONTEXT:
          state.productContext = action.productContext;
          return;
    default:
      throw new Error("Invalid Action");
  }
};

// Context
const Store = React.createContext(null);

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useImmerProducer(actionReducer, initialState);
  return <Store.Provider value={[state, dispatch]}>{children}</Store.Provider>;
};

export const useStore = () => useContext(Store);
