import React from "react";
import { Typography } from "antd";

const { Title } = Typography;
export default function Index({ level, type, style, children, ...props }) {
  return (
    <Title
      level={level}
      type={type||"secondary"}
      style={{ ...style, padding: 0, margin: 0 }}
      {...props}
    >
      {children}
    </Title>
  );
}
