import { Spin } from "antd";
import { useLockBodyScroll } from "react-use";
const FullScreenLoader = () => {
  useLockBodyScroll(true);
  return (
    <div style={{ zIndex: "2000" }} className="full-screen-loader">
      <Spin size="large" />
    </div>
  );
};

export default FullScreenLoader;
