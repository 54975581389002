import { Row, Col, Button } from "antd";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTE } from "../../../constants";
import { logout } from "../../../utils/auth";
import logoutIcon from "../../../assets/images/vector-out.svg";
import { useSWRConfig } from "swr";
export const LogoutComponent = ({
  isButton,
  isStartTrialModal,
  setIsTrialModal,
}) => {
  const navigate = useNavigate();
  const { cache } = useSWRConfig();
  const handleLogout = useCallback(() => {
    cache.clear();
    logout().then(() => {
      if (isStartTrialModal) {
        setIsTrialModal(false);
      }
      navigate(HOME_ROUTE);
    });
  }, [navigate, cache]);
  return (
    <>
      {isButton ? (
        <Row justify="space-between">
          <Col span={10}>
            {" "}
            <Button
              onClick={handleLogout}
              size="large"
              style={{
                borderRadius: "0.3rem",
                color: "#4F5655",
                width: "100%",
                fontSize: "0.9rem",
                border: "1px solid #CDCFCE",
              }}
            >
              Logout
            </Button>
          </Col>
          <Col>
            <a href="mailto:support@sourcewiz.co">
              <Button
                size="large"
                style={{
                  background: "#1D2624",
                  borderRadius: "0.3rem",
                  border: "none",
                  color: "white",
                  fontSize: "0.9rem",
                }}
              >
                Contact support
              </Button>
            </a>
          </Col>
        </Row>
      ) : (
        <Row align="center" style={{ marginTop: "2rem" }}>
          <Col style={{ cursor: "pointer" }} onClick={handleLogout}>
            <img src={logoutIcon} style={{ marginRight: "0.8rem" }} />
            <span>Logout</span>
          </Col>
        </Row>
      )}
    </>
  );
};
