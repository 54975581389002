import { Modal, Typography, Row, Col, Button, message } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import { useGlobal } from "../../../store";
import { LogoutComponent } from "./LogoutComponent";

const { Title, Text } = Typography;
export const StartTrialModal = ({ isStartTrialModal, setIsTrialModal }) => {
  const data = {
    title: "Trade fair assistant",
    benefits: [
      "QR feature for easy cataloging at trade fairs",
      "Get buyer analytics for maximum conversions",
      "Access app to capture buyer leads at one place one place ",
    ],
  };
  const [loading, setLoading] = useState(false);
  const [globalState, globalActions] = useGlobal();
  const [usageData, setUsageData] = useState({});
  const { usage } = globalState;
  useEffect(() => {
    if (Object.keys(usage).length > 0) {
      setUsageData(usage);
    }
  }, [usage]);
  const handleStartTrial = () => {
    setLoading(true);
    const userData = localStorage.getItem("user");
    const user = userData && JSON.parse(userData);
    const access_token = user?.access_token;
    axios
      .post(
        `${process.env.REACT_APP_API_SERVICE}account/enable_trial`,
        {},
        {
          headers: {
            "access-token": `${access_token}`,
          },
          params: {
            module_id: usageData?.module_id,
          },
        }
      )
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          globalActions.setReloadUsageData(true);
          setIsTrialModal(false);
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(`Something went wrong`);
      });
  };
  return (
    <>
      <Modal
        width={336}
        bodyStyle={{
          background:
            "linear-gradient(180deg, #F0F8F6 0%, #F7FDE6 100%, rgba(255, 255, 255, 0) 100%)",
        }}
        visible={isStartTrialModal}
        closable={false}
        // onCancel={() => setIsTrialModal(false)}
        footer={null}
      >
        <br />
        <Row align="center">
          <Title level={4}>{data?.title}</Title>
        </Row>
        {data?.benefits?.map((e) => {
          return (
            <Row align="center" style={{ padding: "0.7rem" }}>
              <Col>
                <CheckCircleFilled
                  style={{ color: "#7DB8AD", marginRight: "0.5rem" }}
                />
              </Col>
              <Col style={{ width: "90%" }}>
                <Text>{e}</Text>
              </Col>
            </Row>
          );
        })}
        <br />
        <Row justify="space-between">
          <Col span={10}>
            <Button
              size="large"
              style={{
                color: "#4F5655",
                border: "1px solid #CDCFCE",
                borderRadius: "0.3rem",
                fontSize: "0.9rem",
                width: "100%",
              }}
              onClick={() => {
                window.open(`https://youtu.be/0xoHhAwVeu4`, "_blank");
              }}
            >
              Learn more
            </Button>
          </Col>
          <Col>
            <Button
              loading={loading}
              size="large"
              style={{
                background: "#1D2624",
                color: "white",
                border: "none",
                borderRadius: "0.3rem",
                fontSize: "0.9rem",
              }}
              onClick={handleStartTrial}
            >
              Start free trial
            </Button>
          </Col>
        </Row>
        <LogoutComponent
          isStartTrialModal={isStartTrialModal}
          setIsTrialModal={setIsTrialModal}
        />
      </Modal>
    </>
  );
};
