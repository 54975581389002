import { getUserSession } from "./auth";
import axios from "axios";
import { supabase } from "./supabase";

const firebaseKey = process.env.REACT_APP_FIREBASE_API_KEY;

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_FIREBASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    apikey: firebaseKey,
  },
});

export const saveCatalogue = async (catalogue) => {
  if (catalogue.collection_id) {
    catalogue.collection_id = parseInt(catalogue.collection_id);
  }
  const user = localStorage.getItem("user");
  const userData = JSON.parse(user);
  var data;
  try {
    data = await axios.post(
      `${process.env.REACT_APP_API_SERVICE}items/collections/collection_from_hash`,
      catalogue,
      {
        headers: {
          [`access-token`]: userData?.access_token,
        },
      }
    );
  } catch (error) {
    data = error;
  }
  return data;
};

export const sendPost = async (
  link,
  payload,
  headers = {},
  params = {},
  method = "POST"
) => {
  const url = new URL(link);
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );
  return fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    ...(payload && { body: JSON.stringify(payload) }),
  });
};

export const sendGet = async (link, params, headers = {}) => {
  const url = new URL(link);
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );
  return await fetch(url, {
    headers,
  });
};

export const postLogin = async (payload) => {
  const result = await sendPost(
    `${process.env.REACT_APP_DIRECTUS}auth/login`,
    payload
  ).then((r) => r.json());
  // throw correct error
  if (result.errors && result.errors.length) {
    throw result.errors[0];
  }
  return result.data;
};

export const fetchOrg = async () => {
  const user = getUserSession();
  if (!user || !user.access_token) throw new Error("No user found");

  const result = await sendGet(`${process.env.REACT_APP_DIRECTUS}users/me`, {
    access_token: user.access_token,
    fields: "*,org.*,org.logo,org.id",
  }).then((r) => r.json());
  localStorage.setItem("org", JSON.stringify(result?.data?.org));
  if (result.errors && result.errors.length) {
    throw result.errors[0];
  }
  return result.data;
};

export const fetchProduct = async (productId) => {
  const user = getUserSession();
  if (!user || !user.access_token) throw new Error("No user found");

  // const realProductId = await sendGet(
  //   `${process.env.REACT_APP_API_SERVICE}items/productsv2/unique_hash_to_product_id`,
  //   {
  //     unique_hash: productId,
  //   }
  // ).then((r) => r.json());

  // if (realProductId.errors && realProductId.errors.length) {
  //   throw realProductId.errors[0];
  // }

  // if (!realProductId || !realProductId.product_id) {
  //   throw new Error("ProductId not found");
  // }

  const result = await sendGet(
    `${process.env.REACT_APP_API_SERVICE}quotation/product/qr/`,
    {
      unique_hash: productId,
      access_token: user.access_token,
      // fields: "*,org.*,org.logo.*",
    }
  ).then((r) => r.json());

  if (result.errors && result.errors.length) {
    throw result.errors[0];
  }
  return result;
};

export const fetchAttrPreference = async () => {
  const user = getUserSession();
  if (!user || !user.access_token) throw new Error("No user found");

  const result = await sendGet(
    `${process.env.REACT_APP_DIRECTUS}items/manufacturer/`,
    {
      access_token: user.access_token,
      fields: "recommendation_preferences",
    }
  ).then((r) => r.json());

  if (result.errors && result.errors.length) {
    throw result.errors[0];
  }
  return result.data[0];
};

export const addBuyer = async (payload) => {
  const { data, error } = await supabase
    .from("qr_buyers_sessions")
    .insert([payload]);

  if (!data || error) {
    throw new Error("Error adding buyer");
  }

  // if (result?.message) {
  //   throw result.message;
  // }
  return data[0];
};

export const fetchBuyerSessions = async (_, manufacturer_id) => {
  const headers = {
    apikey: firebaseKey,
  };
  const result = await sendGet(
    `${process.env.REACT_APP_FIREBASE_URL}/rest/v1/qr_buyers_sessions`,
    {
      manufacturer_id: `eq.${manufacturer_id}`,
    },
    headers
  ).then((r) => r.json());

  if (result?.message) {
    throw result.message;
  }

  return result;
};

export const patchBuyer = async (buyerId, payload) => {
  const headers = {
    apikey: firebaseKey,
    Authorization: `Bearer ${firebaseKey}`,
    Prefer: "return=representation",
  };
  const result = await sendPost(
    `${process.env.REACT_APP_FIREBASE_URL}/rest/v1/qr_buyers_sessions`,
    payload,
    headers,
    {
      id: `eq.${buyerId}`,
    },
    "PATCH"
  ).then((r) => r.json());

  if (!result) {
    throw new Error("Error adding buyer");
  }

  if (result?.message) {
    throw result.message;
  }
  return result[0];
};

export const postMail = async (payload) => {
  const user = getUserSession();
  if (!user || !user.access_token) throw new Error("No user found");

  if (!payload?.sendMail && !payload.is_active) {
    throw new Error("Buyer session inactive");
  }
  const result = await sendPost(
    `${process.env.REACT_APP_API_SERVICE}qr_code_pdf_service/send_new_buyer_lead_mail`,
    payload,
    {
      "access-token": user.access_token,
    }
  );
  const data = await result.json();
  if (!data) {
    throw new Error("Error adding buyer");
  }
  if (~~(result.status / 100) !== 2)
    throw result?.message ?? "Error sending Mail";

  return data;
};

// getAllBuyerSessions
export const getAllBuyerSessions = async () => {
  const response = await axiosInstance.get(`/rest/v1/qr_buyers_sessions`);
  console.log("response", response);
  return response.data;
};
