import { Row, Typography, } from "antd"
import postRequestSvg from "../../../assets/images/post_request_status.svg";

const { Title, Text } = Typography;
export const PostRequestContent = () => {
    return (<>
    <Row align="center" style={{marginTop:"1rem"}}>
        <img src={postRequestSvg}/>
    </Row>
    <Row align="center" style={{marginTop:"2rem"}}>
          <Title level={5} style={{ textAlign: "center" }}>
              We have received your request for upgrade
          </Title>
    </Row>
    <Row style={{marginBottom:"1.5rem"}}>
          <Text style={{ textAlign: "center", color:"#4F5655", fontSize:"0.85rem" }}>
            Our team is actively working on your requirements
          </Text>
        </Row>
    </>)   
}