import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import ProtectedRoute from "./components/support/ProtectedRoute";
import { retry } from "./utils/commonFunctions";
import {
  HOME_ROUTE,
  LOGIN_ROUTE,
  QR_SCANNER_ROUTE,
  BUYER_SESSIONS_ROUTE,
  BUYER_SESSION_ROUTE,
  PROFILE_ROUTE,
  SINGLE_SCAN,
  QR_SCANNER_SESSION_ROUTE,
  PRODUCT,
} from "./constants";
import { StoreProvider } from "./components/support/GlobalContext";
import Navbar from "./components/pages/Navbar";
import { Heimdall } from "./Routes/Guardrails/Heimdall";

const Product = lazy(() => retry(() => import("./Routes/ProductView")));
const Home = lazy(() => retry(() => import("./Routes/Home")));
const Login = lazy(() => retry(() => import("./components/pages/Login")));

const BuyerSessions = lazy(() => retry(() => import("./Routes/BuyerSessions")));
const QrScannerPage = lazy(() =>
  retry(() => import("./components/pages/QrScannerPage"))
);
const BuyerSession = lazy(() => retry(() => import("./Routes/BuyerSession")));

const Profile = lazy(() => retry(() => import("./components/pages/Profile")));

const protectedRoutes = [
  {
    path: HOME_ROUTE,
    element: (
      <>
        <Home />
        <Navbar />
      </>
    ),
  },
  {
    path: QR_SCANNER_ROUTE,
    element: (
      <>
        <QrScannerPage />
        {/* <Navbar  /> */}
      </>
    ),
  },
  {
    path: QR_SCANNER_SESSION_ROUTE,
    element: (
      <>
        <QrScannerPage />
        {/* <Navbar  /> */}
      </>
    ),
  },
  {
    path: BUYER_SESSIONS_ROUTE,
    element: (
      <>
        <BuyerSessions />
        <Navbar />
      </>
    ),
  },
  {
    path: PROFILE_ROUTE,
    element: (
      <>
        <Profile />
        <Navbar />
      </>
    ),
  },
  {
    path: SINGLE_SCAN,
    element: <QrScannerPage />,
  },
  {
    path: BUYER_SESSION_ROUTE,
    element: (
      <>
        <BuyerSession />
        <Navbar />
      </>
    ),
  },
  {
    path: PRODUCT,
    element: (
      <>
        <Product />
        <Navbar />
      </>
    ),
  },
];

function App(props) {
  console.log("history = ", props.history);
  return (
    <div className="app">
      <StoreProvider>
        <Router>
        <Heimdall/>
          <Routes>
            <Route
              path={LOGIN_ROUTE}
              element={
                <Suspense fallback={<div />}>
                  <Login />
                </Suspense>
              }
            />
            {protectedRoutes.map((route) => (
              <Route
                path={route.path}
                key={route.path}
                element={
                  <ProtectedRoute>
                    <Suspense fallback={<div />}>{route.element}</Suspense>
                  </ProtectedRoute>
                }
              />
            ))}
          </Routes>
        </Router>
      </StoreProvider>
    </div>
  );
}

export default App;
