
import globalHook from 'use-global-hook';

//initial state
const initialState = {
    usage: {},
    reloadUsageData: false,
};

//actions
const actions = {
    setUsage: (store, payload) => {
        store.setState({ usage: payload });
      },
    setReloadUsageData: (store, payload) => {
        store.setState({ reloadUsageData: payload });
    }
}

export const useGlobal = globalHook(initialState, actions);