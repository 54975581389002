import {
  Modal,
  Typography,
  Row,
  Form,
  Input,
  Divider,
  Button,
  Col,
} from "antd";
import axios from "axios";
import { useState } from "react";
import { LogoutComponent } from "./LogoutComponent";
import { RequestStatusModal } from "./RequestStatusModal";

const { Title, Text } = Typography;
const { TextArea } = Input;
export const UpgradeRequestModal = ({
  data,
  isExpired,
  upgradeRequestModal,
  setUpgradeRequestModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [requestStatusModal, setRequestStatusModal] = useState(false);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    if (data) {
      setLoading(true);
      const userData = localStorage.getItem("user");
      const user = userData && JSON.parse(userData);
      const access_token = user?.access_token;
      const payload = {
        ...data,
        user_comments: values?.message || "",
        meta: `{"contact_no": ${values?.contact}}`,
      };
      axios
        .post(`${process.env.REACT_APP_API_SERVICE}user_request/add`, payload, {
          headers: { "access-token": `${access_token}` },
        })
        .then((res) => {
          if (res?.status === 200) {
            setUpgradeRequestModal(false);
            setRequestStatusModal(true);
            setLoading(false);
            form.resetFields();
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };
  return (
    <>
      <Modal
        width={336}
        closable={false}
        footer={null}
        visible={upgradeRequestModal}
        onCancel={() => setUpgradeRequestModal(false)}
      >
        <Row>
          <Title level={5}>Request to {data?.feature_type ==="plan" ? "upgrade plan" : "buy trade fair assistant"}</Title>
        </Row>
        <Row>
          <Text>
            Share your requirements below and our team will reach out to you
            shortly
          </Text>
        </Row>
        <br />
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="contact"
            label="Contact number"
            rules={[{ required: true,min: 10, max: 10, message: "Please enter a valid phone number"},]}
          >
            <Input  type={"number"}/>
          </Form.Item>
          <Form.Item name={"message"} label={"Message"}>
            <TextArea rows={5} />
          </Form.Item>
          <Divider />
          <Row justify="space-between">
            <Col>
              <Button
                size="large"
                style={{borderRadius:"0.3rem"}}
                onClick={() => {
                  form.resetFields();
                  setUpgradeRequestModal(false)}}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                style={{ background: "black", color: "white", border: "none", borderRadius:"0.3rem"}}
                htmlType="submit"
                loading={loading}
              >
                Send Request
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <RequestStatusModal
        isExpired={isExpired}
        requestStatusModal={requestStatusModal}
        setRequestStatusModal={setRequestStatusModal}
      />
    </>
  );
};
