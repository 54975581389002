export const getGlobal = (id) => {
  const item = localStorage.getItem(id);
  try {
    return JSON.parse(item);
  } catch (e) {
    return item;
  }
};

// https://swr.vercel.app/docs/revalidation#disable-automatic-revalidations
// https://swr.vercel.app/docs/options#options
export const swrOptions = {
  // focusThrottleInterval: 5000
};

export const setGlobal = (id, value) => {
  if (!value) {
    localStorage.removeItem(id);
  }
  const item = JSON.stringify(value);
  localStorage.setItem(id, item);
};

export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const getPagination = (page, size) => {
  const limit = size ? +size : 3;
  const from = page ? page * limit : 0;
  const to = page ? from + size : size;

  return { from, to };
};