import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { LOGIN_ROUTE } from "../../constants";
import { isLoggedIn } from "../../utils/auth";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();

  if (!isLoggedIn()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={LOGIN_ROUTE} state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
