// import {
//   HOME_ROUTE,
//   LOGIN_ROUTE,
//   QR_SCANNER_ROUTE,
//   BUYER_SESSIONS_ROUTE,
//   PROFILE_ROUTE,
// } from "./constants";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   useNavigate,
// } from "react-router-dom";

import React, { lazy, Suspense, useState, useEffect, useCallback } from "react";
import LogoutButton from "../partial/LogoutButton";
import useSwr from "swr";
import { message, Image, Row, Col } from "antd";
import { fetchOrg, fetchBuyerSessions } from "../../utils/api";
import Banner from "../../assets/images/banner.png";
import QrScanImage from "../../assets/images/qr-scan.svg";
import { retry, swrOptions } from "../../utils/commonFunctions";
import { useStore } from "../support/GlobalContext";
import { getBuyerSession } from "../../utils/buyerSession";
import FullScreenLoader from "../loaders/FullScreenLoader";
import { useNavigate, useLocation } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import {
  QR_SCANNER_ROUTE,
  HOME_ROUTE,
  PROFILE_ROUTE,
  BUYER_SESSIONS_ROUTE,
} from "../../constants";
import Title from "../../react-common-components/Title";
import Text from "antd/lib/typography/Text";
import Button from "../../react-common-components/Button";
import { RightOutlined } from "@ant-design/icons";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { MdPerson, MdOutlinePeopleAlt, MdHome } from "react-icons/md";
/**
 *
 */
function Navigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const [navItem, setNavItem] = useState(0);
  const redirectMap = {
    home: HOME_ROUTE,
    buyers: BUYER_SESSIONS_ROUTE,
    profile: PROFILE_ROUTE,
  };
  const paths = Object.values(redirectMap);

  useEffect(() => {
    let currentLocationIndex = paths.indexOf(location.pathname);
    if (location.pathname.includes("buyer-session")) currentLocationIndex = 1;
    setNavItem(currentLocationIndex);
  });

  const handleNavigation = useCallback(
    (redirectTo) => {
      navigate(redirectMap[redirectTo]);
    },
    [navigate]
  );

  return (
    <BottomNavigation
      showLabels
      value={navItem}
      onChange={(event, newValue) => {
        setNavItem(newValue);
      }}
      style={{
        position: "sticky",
        left: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "space-around",
        marginTop: "auto",
        zIndex: 99,

        // cursor:'pointer',
      }}
    >
      <BottomNavigationAction
        label="Home"
        onClick={() => handleNavigation("home")}
        icon={<MdHome size={25} />}
      />
      <BottomNavigationAction
        label="Buyers"
        onClick={() => handleNavigation("buyers")}
        icon={<MdOutlinePeopleAlt size={25} />}
      />
      <BottomNavigationAction
        label="Profile"
        onClick={() => handleNavigation("profile")}
        icon={<MdPerson size={25} />}
      />
    </BottomNavigation>
  );
}

export default Navigation;
