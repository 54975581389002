import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useGlobal } from "../../../store";
import { UpgradePlanModal } from "../DemoAccountStates/UpgradePlanModal";
import { StartTrialModal } from "../DemoAccountStates/StartTrialModal";
import { TrialExpiredModal } from "../DemoAccountStates/TrialExpiredModal";

export const Heimdall = () => {
  const [startTrialModal, setStartTrialModal] = useState(false);
  const [trialExpiredModal, setTrialExpiredModal] = useState(false);
  const [isDemoExpired, setIsDemoExpired] = useState(false);
  const [globalState, globalActions] = useGlobal();
  const { usage, reloadUsageData } = globalState;
  const qr_Module_Name = "MOD-0008";
  const getUsage = useCallback(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user && user?.access_token;
    axios
      .get(
        `${process.env.REACT_APP_API_SERVICE}usage/?access_token=${accessToken}`
      )
      .then((res) => {
        if (res?.status === 200) {
          const planUsage = res?.data?.plan;
          const module_usage = res?.data?.module_limits;
          if (
            planUsage?.core_plan?.name === "demo" &&
            planUsage?.status === "expired"
          ) {
            setIsDemoExpired(true);
          }
          const qr_usage = module_usage?.filter(
            (item) => item?.module_name === qr_Module_Name
          );
          if (qr_usage) {
            globalActions.setUsage(qr_usage[0]);
            getUsageStatus(qr_usage[0]);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [globalActions]);
  const getUsageStatus = (data) => {
    if (data?.plan_type === "trial") {
      if (data?.plan_status === "expired") {
        setTrialExpiredModal(true);
      } else if (data?.plan_status === "inactive") {
        setStartTrialModal(true);
      }
    }
  };
  useEffect(() => {
    getUsage();
  }, [getUsage]);
  useEffect(() => {
    if (reloadUsageData) {
      getUsage();
      globalActions.setReloadUsageData(false);
    }
  }, [getUsage, reloadUsageData]);
  return (
    <>
      <StartTrialModal
        isStartTrialModal={startTrialModal}
        setIsTrialModal={setStartTrialModal}
      />

      <TrialExpiredModal
        isModalVisible={isDemoExpired}
        setIsModalVisible={setIsDemoExpired}
      />
      <UpgradePlanModal
        data={usage}
        isExpired={true}
        isUpgradeModal={trialExpiredModal}
        setIsUpgradeModal={setTrialExpiredModal}
      />
    </>
  );
};
