import React from "react";
import { Button } from "antd";

const buttonStyle = (variant) => {
  let newStyle = {};
  switch (variant) {
    case "outline":
      newStyle = {
        border: "1px solid #0B7764",
        borderRadius: "4px",
        color: "#0B7764",
        backgroundColor: "#F0F8F6",
      };
      break;
    case "primary":
      newStyle = {
        backgroundColor: "#0B7764",
        border: "1px solid #0B7764",
        borderRadius: "4px",
        color: "#fff",
      };
      break;
    case "dark":
      newStyle = {
        backgroundColor: "#001529",
        border: "1px solid #001529",
        borderRadius: "4px",
        color: "#fff",
      };
      break;
    default:
      break;
  }
  return newStyle;
};
export default function Index({
  level,
  type,
  style,
  children,
  variant,
  size,
  ...props
}) {
  return (
    <Button
      level={level}
      type={type || "secondary"}
      style={{ ...style, ...buttonStyle(variant) }}
      {...props}
      size={size || "large"}
    >
      {children}
    </Button>
  );
}
